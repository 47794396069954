code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

@font-face {
    font-family: 'Univers Condensed';
    src: url('font/UniversCondensed.woff2') format('woff2'),
    url('font/UniversCondensed.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

h1, h2, h3, h4, h5 {
    font-family: 'Univers Condensed';
    color: #277EC0;
}


td {
    padding-right: 30px;
}

p, li {
    font-size: 16px;
}

label {
    font-size: 14px;
    margin-bottom: 0.2rem;
}

a {
    cursor: pointer;
    text-decoration: underline;
}

a:hover {
    text-decoration: underline !important;
}

img {
    max-width: 100%;
}

.box {
    margin-top: 40px;
    background-color: white;
    padding: 25px;
    border-radius: 8px;
}

.box-title-separator {
    margin-left: -25px;
    margin-right: -25px;
}

.memberTableContainer {
    overflow-x: auto;
}

.memberTable {
    width: 2000px;
}

.tablebutton {
    margin-top: 20px;
}

.Login {
    text-align: right;
}

.acceptbutton {
    margin-right: 5px;
}

.loginfields {
    text-align: left;
}

.divider {
    font-size: 16px;
    margin: 15px 0;
    text-align: center;
    position: relative;
}

.divider::before {
    content: " ";
    display: block;
    position: absolute;
    height: 1px;
    margin: 0;
    width: 46%;
    top: 50%;
    left: 0;
    background: linear-gradient(90deg, #fff, #ddd);
}


.divider::after {
    content: " ";
    display: block;
    position: absolute;
    height: 1px;
    margin: 0;
    width: 46%;
    top: 50%;
    background: linear-gradient(90deg, #ddd, #fff);
    right: 0;
    left: auto;
}

.btn-block {
    margin-top: .5rem;
}

.flex {
    display: flex;
    justify-content: space-between;
}

.login-error-alert {
    text-align: center;
    position: fixed !important;
    z-index: 299;
    /*top: 66px;*/
    width: 100%;
}

.MyAssociations {
    height: 500px;
    width: 100%;
}

.MyAssociation {
    margin-bottom: 40px;
}

.switchButton {
    position: absolute;
    right: 0;
}

.UserPanel {
    display: flex;
    justify-content: space-around;
}

.backbutton {
    position: absolute;
    z-index: 100;
}

.committeeMembersBtnLink {
    color: white;
    text-decoration: none;
}

.committeememberTable {
    width: 500px;
}

.committeememberTableContainer {
    overflow-x: auto;
}

.tablebutton {
    margin-top: 20px;
}

.IncomingRequests {
    margin: 20px auto;
    margin-top: 40px;
    padding: 30px;
}

.allMembersNavbar {
    display: flex;
    justify-content: space-between;
}

.IncomingRequest {
    display: flex;
    justify-content: space-between;
}

.incomingRequestName {
    display: inline;
}

.allMembersBtn {
    width: 100%;
}

.allMembersBtnLink {
    color: white;
    text-decoration: none;
}

.OutgoingRequest {
    display: flex;
    justify-content: space-between;
}

.outgoingRequestName {
    display: inline;
}

.search-input {
    padding: 10px 10px;
    height: 52px;
    position: relative;
}

.search-input::before {
    content: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAA8AAAAPCAYAAAA71pVKAAAAAXNSR0IArs4c6QAAAQJJREFUKBWVkr2uQUEUhf3ET6GRaC5aFRoJKrf1BKpb8SwqovYGXkCj00k0QnRKEYkILYobvpUYmeMMyVnJl7P3mjN7Zu9zwiGv2qRFyMMSRrAFp6JPN8XzBj+wgDkUYAg7WINTYdwpDECxrRLJHeq2accdkgm8bzTvNAg2EDOGeUYI1KNO1gkuzTA1g8T7ojbn4ONQWPuHPWgeHmnzCqoe15tkSNPgPEAn68oVcOmA2XMtGK9FoE/VhOTTVNExqLCGZnxCv2pYauEC6lF0oQxX6IOvb7yX9NPEQafan+aPXDdQC18LsO6Tip5BBY6gIQaSbnMCFRCBZRcIvFkbsvCr4AFGOCxQy+JdGQAAAABJRU5ErkJggg==');
    display: block;
    position: absolute;
    width: 15px;
    z-index: 3;
    height: 15px;
    font-size: 20px;
    top: 11px;
    left: 16px;
    line-height: 32px;
    opacity: 0.6;
}

.search-input > input {
    width: 100%;
    font-size: 18px;
    border: none;
    line-height: 22px;
    padding: 5px 10px 5px 25px;
    height: 32px;
    position: relative;
}

.search-input > input:focus {
    outline: none;
}

.AvailableAssociations {
    max-height: 250px;
    overflow-y: auto;
}

.AvailableAssociation {
    cursor: pointer;
}

/*boostrap overwrites*/

.form-group {
    margin-bottom: 0.5rem !important;
}

.button-mt-20 {
    margin-top: 20px !important;
}

.align-btn-right {
    text-align: right;
}

.spinner-border {
    align-items: center !important;
    display: flex !important;
    justify-content: center !important;
    margin: auto !important;
    margin-top: 66px !important;
}

.allMembersBtnLink:hover {
    text-decoration: none !important;
}

.addCommitteeBtnLink:hover, .addCommitteeBtnLink {
    text-decoration: none !important;
}

.card-wrapper {
    top: 40px;
    position: relative;
}

.noUnderline {
    text-decoration: none;
    text-decoration-color: black;
}

.dropdown-link:hover {
    text-decoration: none !important;
    cursor: pointer;
}

